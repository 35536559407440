import { ErrorBoundary } from '@sentry/react'
import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFound from 'src/components/base/notFound/NotFound'

const AsyncPriceList = lazy(() => import('./price-list/PriceList'))

const Directory = () => (
  <Switch>
    <Route exact path="/:locale/catalog/:sellerId/:buyerCompanyUuid?/">
      <ErrorBoundary>
        <AsyncPriceList />
      </ErrorBoundary>
    </Route>
    <Route>
      <ErrorBoundary>
        <NotFound />
      </ErrorBoundary>
    </Route>
  </Switch>
)

export default Directory
